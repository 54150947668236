import React from 'react';
import CourseList from './CourseList';


export default function MyCourses() {
  
  return (

    <div className="container mx-auto py-4">
      <CourseList />
    </div>
  )
}
