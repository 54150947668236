
import ComplexTable from "./components/UsersProgress";

const UsersProgress = () => {
  return (
    <div>
      <ComplexTable/>
    </div>
  );
};

export default UsersProgress;
